import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {BsPlus} from 'react-icons/bs';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import reactHtmlParser from "react-html-parser";
import Title from "../Title";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";
// import {Img} from "../Img";

const Strength = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {
    const info = data?.posts?.list;

    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)


    useEffect(() => {
        // window.addEventListener('load', function () {
        setOffset(document.querySelector(' .container')?.offsetLeft)

        // })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        setOffset(document.querySelector(' .container')?.offsetLeft)
    }, [])


    // half background calculation
    const [theHeight, setTheHeight] = useState()
    useEffect(() => {
        if (window.innerWidth > 767) {
            const titleHeight = document.querySelector('.business-domain h2')?.clientHeight + 90
            const getItemHeight = document.querySelector('.business-domain .about-section__bottom__single')?.clientWidth / 2
            setTheHeight((titleHeight + getItemHeight + 200) - 14)
        } else {
            const titleHeight = document.querySelector('.business-domain h2')?.clientHeight - 50
            const getItemHeight = document.querySelector('.business-domain .about-section__bottom__single')?.clientWidth / 2
            setTheHeight((titleHeight + getItemHeight + 200) - 14)
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                const titleHeight = document.querySelector('.business-domain h2')?.clientHeight + 90
                const getItemHeight = document.querySelector('.business-domain .about-section__bottom__single')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 200) - 14)
            } else {
                const titleHeight = document.querySelector('.business-domain h2')?.clientHeight - 50
                const getItemHeight = document.querySelector('.business-domain .about-section__bottom__single')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 200) - 14)
            }
        })

    }, [data])


    // font size change based on hover list item
    // useEffect(() => {
    //     let getAllItems = document.querySelectorAll('.about-section__bottom__single__inner');
    //     getAllItems.forEach(function (e) {
    //         if (e.querySelectorAll('ul li')?.length > 10) {
    //             e.classList.add('item-extended')
    //         }
    //     })
    // }, [data])


    return (
        <StyledStrength theHeight={theHeight} getAfterHeight={getAfter} remove={remove} id={`${id ? id : 'Strength'}`}
                        offset={offset}
                        className={`pionneering business-domain parallax-inner-box ${padding ? padding : 'pt-200 pb-200 divider'}  `}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={data?.title}/>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className='about-section__bottom'>
                <Row>

                    {
                        data?.list && data?.list?.length > 0
                        && data.list.map((element, index) => {
                            return (

                                <Col sm={4} className='about-section__bottom__single'>
                                    <div className="about-section__bottom__single__inner">

                                        {/*{element?.image &&*/}
                                        <div className="about-section__bottom__single__inner__img">
                                            {element?.image &&
                                                // <div className={'reveal'}>
                                                <Img src={element?.image}/>
                                                // </div>
                                            }
                                        </div>
                                        {/*}*/}

                                        <div className="circle">
                                            <BsPlus/>
                                        </div>
                                        <div className="hover-top">
                                            <h6>{reactHtmlParser(element?.subtitle)}</h6>
                                            {reactHtmlParser(element?.description)}

                                        </div>
                                        <h4>{reactHtmlParser(element?.title)}</h4>
                                        <span>{index + 1}</span>

                                    </div>
                                </Col>
                            );
                        })
                    }

                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`

  position: relative;
  overflow: hidden;

  &:after {
    height: calc(100% - ${(p) => p.theHeight + 'px'});
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F6F6F6;
    content: "";
    z-index: -1;
  }

  .title_wrapper {
    display: flex;
  }


  .about-section__bottom {
    margin-top: 60px;
    padding-left: 15px !important;
    position: relative;


    &__single {
      max-width: 100%;
      margin-top: 30px;

      &__inner {
        position: relative;
        padding-top: calc(370 / 370 * 100%) !important;
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #E9E9E9;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s cubic-bezier(0.4, 0, 0, 1);
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ECECEC;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s cubic-bezier(0.4, 0, 0, 1);
            transition-delay: .3s;
          }
        }

        .hover-top {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 40px;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
          transform: translateY(-100%);
          z-index: 2;

          h6 {
            font-size: 28px !important;
            font-weight: 600 !important;
            line-height: 36px !important;
            color: #061524;
            margin-bottom: 20px;
          }

          ul {
            padding-left: 20px !important;

            li {
              position: relative;
              padding-left: 20px;
              color: #061524;
              font-weight: 300;
              //font-size: 16px;
              //line-height: 28px;
              font-size: 13px;
              line-height: 19px;

              &:nth-child(n+13) {
                display: none;
              }

              &:after {
                position: absolute;
                content: '';
                height: 6px !important;
                width: 6px !important;
                background-color: #061524 !important;
                left: 0;
                top: 6px !important;
                //bottom: 0;
                margin: 0 !important;
              }
            }
          }
          @media (min-width: 992px) and (max-width: 1200px) {
          ul {
            li {
              //font-size: 20px;
              line-height: 15px !important;

              &:after {
                height: 15px;
                width: 15px;
                top: 4px !important;
              }
            }
          }
        }

          @media (min-width: 1550px) {
            h6 {
              font-size: 32px;
              line-height: 40px;
            }

            ul {
              li {
                font-size: 20px;
                line-height: 24px;

                &:after {
                  height: 15px;
                  width: 15px;
                  top: 7px !important;
                }
              }
            }
          }

        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          color: #FFF;
          z-index: 2;
          //transition: all .5s cubic-bezier(0.4, 0, 0, 1);
        }

        .icon {
          position: absolute;
          top: 40px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 40px;
          left: 40px;
          font-size: 80px;
          line-height: 48px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

        &.item-extended {
          ul li {
            font-size: 13px;
            line-height: 19px;
            @media (min-width: 1600px) {
              font-size: 20px;
              line-height: 28px;
            }
            

            &:after {
              top: -4px !important;
              margin: auto !important;

            }
          }
        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #061524;
                fill: #061524;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }


          .hover-top {
            transform: none;
            opacity: 1;
            color: #4F616B;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

          h4 {
            //color: transparent;
            display: none;

          }

        }

      }
    }
  }


  @media (max-width: 991px) {
    .about-section__bottom__single {
      min-width: 50%;
    }
  }

  @media (max-width: 767px) {
    //&:after {
    //  height: 80%;
    //}
    .about-section__bottom__single__inner .hover-top {
      left: 20px !important;
      right: 20px !important;
    }

    .navigation_wrapper {
      flex-wrap: wrap;
    }

    .about-section__bottom {
      padding-left: 15px !important;
      padding-right: 15px !important;
      margin-top: 0;

      &__single {
        min-width: 100%;
      }
    }

    .navigation {
      min-width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .about-section__bottom__single__inner span {
      top: 40px;
      left: 30px;
    }
  }




`;

export default Strength;