import React, {useEffect} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchCareer} from "../../api/redux/Career";
import InnerBannerV2 from "../../components/InnerBannerV2";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import CareerLists from "../../components/career/CareerLists";
import GetInTouch from "../../components/GetInTouch";
import {Loading} from "../../components/Loading";

const MyComponent = () => {
    const dispath = useDispatch()

    const title = 'Career'
    const text = 'Kickstart your career us and take the next big step towards success'

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAREER
        dispath(fetchCareer([api_url]))
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    let getPost = useSelector(state => state.career)
    //console.log(getPost);

    //Refactor Data

    const banner = getPost?.posts?.[0]?.banner;
    const footerTop = getPost?.posts?.[0]?.footer_top;
    const jobs = getPost?.posts?.[0]?.jobs?.list;
    const meta = getPost?.posts?.[0]?.meta;
    const privacy_policy = getPost?.posts?.[0]?.privacy_policy;



    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'Career'} | NDE Infratech`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'NDE Infratech specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>


            <StyledComponent>
                {/*{getPost?.loading && <Loading/>}*/}
                <InnerBannerV2 img={banner?.image} title={banner?.subtitle} text={banner?.title} mobile_img={banner?.mobile_image}/>
                <PrivacyPolicy description={privacy_policy?.privacy_policy} careerPage data={privacy_policy}/>
                <CareerLists data={jobs}/>
                <GetInTouch data={footerTop}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`


p {
    margin-bottom: 24px;
}

.custom-list {
    padding: 0;
    margin-bottom: 24px;

    li {

      font-weight: 300;
      position: relative;
      padding-left: 20px;
      //padding-top: 20px;
      &:not(:last-child) {
        padding-bottom: 10px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        margin-top: -5px;
        width: 6px;
        height: 6px;
        box-sizing: content-box;
        background-color: #fff;
      }
    }

  }

`;

export default MyComponent;
