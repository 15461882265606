import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";


import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
// import required modules
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import 'swiper/css/scrollbar';
import ReactHtmlParser from "react-html-parser";
import {blue, hover, text} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({background, data, id, home}) => {


    // const slide_title = slide?.data?.title;
    // const slide_desc = slide?.data?.description;
    // const slide_img = slide?.images[0]?.full_path;


    const slidenextRef = useRef()
    const slideprevRef = useRef()
    const [current, setCurrent] = useState('1')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }
    return (
        <StyledComponent id={id} className={`${home ? '' : ' pb-200'} testimonials divider`}>

            <Container className={'p-0'}>
                <Col sm={12}>
                    <Title marginMobile={'0 0 40px 0'} margin={'0 0 60px 0'}
                           text={data?.title}/>
                </Col>


                <Swiper
                    Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                    allowTouchMove={true}
                    longSwipesMs={500}
                    slidesPerView={1}
                    pagination={{
                        // el: '.swiper-pagination',
                        type: "fraction",
                    }}
                    navigation={{
                        prevEl: slideprevRef.current,
                        nextEl: slidenextRef.current,
                    }}

                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = slideprevRef.current;
                        swiper.params.navigation.nextEl = slidenextRef.current;
                    }}
                    shouldSwiperUpdate={(prev, next) => {
                        if (prev.activeIndex === next.slides.length - 1) {
                            setButtonDisabled(true);
                        } else {
                            setButtonDisabled(false);
                        }
                    }}
                    loop={false}
                    speed={900}
                    onSlideChange={(s) => handleSliderCurrent()}
                >
                    {
                        data?.list && data?.list.length > 0
                        && data?.list?.map((element, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="testimonials__slider d-flex">
                                        <Col sm={6}>
                                            <div className="testimonials__slider__img ">
                                                <Img src={element?.image}/>
                                            </div>
                                        </Col>

                                        <Col sm={6} className={'testimonials__slider__content'}>
                                            {element?.description && <p>{ReactHtmlParser(element?.description)}</p>}
                                            {element?.name && <h4>{ReactHtmlParser(element?.name)}</h4>}
                                            {element?.designation &&
                                                <p className={'designation'}>{ReactHtmlParser(element?.designation)}</p>}

                                        </Col>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
                <Row className={'slider--nav'}>
                    <Col sm={{span: 6, offset: 6}}>
                        <ul>
                            <li className={'prev_swipper hover'} ref={slideprevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                     viewBox="0 0 7.828 12.828">
                                    <g id="Group_6" data-name="Group 6" transform="translate(1.414 11.414) rotate(-90)">
                                        <line id="Line_4" data-name="Line 4" y1="5" x2="5" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_5" data-name="Line 5" x1="5" y1="5" transform="translate(5)"
                                              fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>


                            </li>
                            <li className={'next_swipper hover'} ref={slidenextRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                     viewBox="0 0 7.828 12.828">
                                    <g id="Group_6" data-name="Group 6"
                                       transform="translate(-92.086 454.914) rotate(-90)">
                                        <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                              transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                              transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>

                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section` {
  background-color: #F6F6F6;
  overflow: hidden;

  .container {
    position: relative;
  }

  //.global-image{
  //  width: 70%;
  //  height: 70%;
  //}

  img {
    width: 80%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .testimonials__slider {
    &__img {
      .global-image {
        background-image: none !important;
      }

      position: relative;
      padding-top: 80%;
      //margin-right: 50px;
      min-height: 100%;

      @media (max-width: 767px) {
        padding-top: 100%;
      }

      img {
        max-width: 100%;
      }

      svg {
        position: absolute;
        right: -50px;
        top: -50px;

        path {
          fill: #C1C1C1;
        }
      }

      .qr {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        height: 150px;
      }
    }
  }

  .testimonials__slider__content {
    padding-bottom: 70px;

    .designation {
      color: #404040 !important;
      line-height: 20px !important;

      //br {
      //  display: none;
      //}
      //
      //i {
      //  display: block;
      //}
    }

    p {
      color: #404040;

      &:nth-last-of-type(1) {
        color: #C1C1C1;
      }
    }

    h4 {
      margin-top: 60px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #404040;

    }


  }

  ul {
    position: absolute;
    bottom: 0;
    left: 15px;
    z-index: 150;
    display: flex;

    .hover:after {
      background-color: ${hover};
    }

    li {
      cursor: pointer;

      svg {
        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          r: 0;
        }


        &:hover {
          #Ellipse_4378 {
            fill: ${hover};
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26;
          }

          g {
            line {
              stroke: #f9f9f9;
            }
          }
        }
      }

      &:not(:nth-last-of-type(1)) {
        margin-right: 20px;
      }
    }

  }
}

  .swiper {
    //padding-top: 60px;
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-pagination {
    display: flex;
    position: absolute;
    width: fit-content;
    right: 15px;
    left: auto;

    font-size: 16px;
    font-weight: 500;

    span {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .slider--nav {
    position: absolute;
    width: 100%;
    bottom: 0px;

    li {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: #404040;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }

  }

  @media (max-width: 767px) {
    .d-flex {
      flex-wrap: wrap;
    }

    .testimonials__slider {
      .col-sm-6 {
        min-width: 100%;
      }
    }

    .testimonials__slider__img {
      margin-right: 0;

      svg {
        height: 50px;
        right: -8px;
        width: 50px;
        top: -23px;
      }
    }

    .testimonials__slider__content {
      margin-top: 40px !important;
    }

    .testimonials__slider__content h4 {
      margin-top: 40px;
    }

    .slider--nav {
      position: relative;
      bottom: 0;
      display: block;
      padding: 0 15px;
      margin-top: 16px;

      .col-sm-6 {
        min-width: 100%;
        margin: 0;
      }
    }

    .swiper-pagination {
      right: 15px;
      bottom: 0;
    }

    .swiper {
      //padding-top: 20px;
      padding-bottom: 10px;
    }

  }


`;


export default MyComponent;
