import {Route, Switch, useLocation} from 'react-router-dom';
import React, {useEffect, useLayoutEffect, useRef} from 'react';
import Error from './pages/404'
import GlobalStyle from "./styles/globalStyle";
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';
import Components from './pages/components';
import Home from './pages/home';
import About from './pages/about';
import Business from './pages/business';
import Media from './pages/media';
import Contact from './pages/contact';
import Career from './pages/career';
import Project from './pages/projects';
import ProjectDetail from './pages/projects/single';
import MediaDetail from './pages/media/single';
import MainMenu from "./components/MainMenu";
import {ToastContainer} from "react-toastify";
import Footer from "./components/Footer";
import Responsibility from "./pages/responsibility";
import Test from "./pages/test";
import TalentDetail from "./components/Projects/TalentDetail";
import {Parallax, ParallaxImg, ParallaxItem} from "./animation/parallax";
import {ImageReveal} from "./animation/imageReveal";
import {SplitUp} from "./animation/TextAnimation";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap, TimelineLite} from "gsap";
import {useDispatch, useSelector} from "react-redux";
import {loading} from "./api/redux/global";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";
import PageTransition from "./animation/PageTransition";


function App() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);
    const location = useLocation();
    let MainStore = useSelector(state => state)
    let store = useSelector(state => state.home)
    const dispatch = useDispatch()
    const el = useRef();

    // parallax
    Parallax()
    ParallaxImg()
    ParallaxItem()
    // image reveal
    ImageReveal()
    // text test
    SplitUp()
    // page transition
    PageTransition()

    // smooth scroll
    let smoother;
    useLayoutEffect(() => {
        smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true, // looks for data-speed and data-lag attributes on elements
            // speed: 2,
        });
        ScrollTrigger.refresh();

        if (location.hash) {
            setTimeout(() => {
                smoother.scrollTo(location.hash, 3)
            }, 300)
        }
    }, [location, location.pathname, location.hash])


    return (
        <React.Fragment>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>

            <GlobalStyle/>
            <MainMenu/>

            {/*page transition */}
            <div className="page-loader">
                <svg viewBox="0 0 60 40">
                    <defs>
                        <clipPath id="clip-path">
                            <path id="Path_8508" data-name="Path 8508"
                                  d="M5.7,20.3l5.5-10.1a1.02,1.02,0,0,1,.8-.5l11.3.2c.1,0,.2-.1.1-.2L17.1,1a1.234,1.234,0,0,0-.8-.4H6.3a.622.622,0,0,0-.6.4L.9,10a1.387,1.387,0,0,0,0,.9l4.6,9.5A.31.31,0,0,0,5.7,20.3Z"
                                  transform="translate(-5.6 -0.2)"/>
                        </clipPath>
                        <clipPath id="clip-path-2">
                            <path id="Path_8509" data-name="Path 8509"
                                  d="M28.5,19.7,34,9.5a1.02,1.02,0,0,1,.8-.5L46,9.3c.1,0,.2-.1.1-.2L39.8.4a.67.67,0,0,0-.7-.4h-10a.622.622,0,0,0-.6.4l-4.8,9a1.387,1.387,0,0,0,0,.9l4.6,9.5A.31.31,0,0,0,28.5,19.7Z"
                                  transform="translate(-28.4 0.4)"/>
                        </clipPath>
                        <clipPath id="clip-path-3">
                            <path id="Path_8510" data-name="Path 8510"
                                  d="M40.5,19.6,35,29.8a1.02,1.02,0,0,1-.8.5l-11.3-.2c-.1,0-.2.1-.1.2L29.1,39a1.234,1.234,0,0,0,.8.4h10a.622.622,0,0,0,.6-.4l4.8-9a1.388,1.388,0,0,0,0-.9l-4.6-9.5S40.5,19.5,40.5,19.6Z"
                                  transform="translate(-31.3 -24.6)"/>
                        </clipPath>
                        <clipPath id="clip-path-4">
                            <path id="Path_8511" data-name="Path 8511"
                                  d="M17.7,20.2,12.2,30.3a1.02,1.02,0,0,1-.8.5L.1,30.7c-.1,0-.1.1-.1.2l6.3,8.7a1.234,1.234,0,0,0,.8.4h10a.622.622,0,0,0,.6-.4l4.8-9a1.387,1.387,0,0,0,0-.9l-4.6-9.5Z"
                                  transform="translate(-8.6 -25.2)"/>
                        </clipPath>
                    </defs>
                    <g id="Group_20310" data-name="Group 20310" transform="translate(0 0)">
                        <g id="Group_20302" data-name="Group 20302" transform="translate(5.6 0.2)"
                           clip-path="url(#clip-path)">
                            <line id="Line_4017" data-name="Line 4017" x1="9.2" y1="15.2" fill="none" stroke="#fff"
                                  stroke-miterlimit="10" stroke-width="21"/>
                        </g>
                        <g id="Group_20305" data-name="Group 20305">
                            <g id="Group_20304" data-name="Group 20304" transform="translate(28.4 -0.4)"
                               clip-path="url(#clip-path-2)">
                                <line id="Line_4018" data-name="Line 4018" x1="9.2" y1="15.1" fill="none" stroke="#fff"
                                      stroke-miterlimit="10" stroke-width="21"/>
                            </g>
                        </g>
                        <g id="Group_20307" data-name="Group 20307">
                            <g id="Group_20306" data-name="Group 20306" transform="translate(31.3 24.6)"
                               clip-path="url(#clip-path-3)">
                                <line id="Line_4019" data-name="Line 4019" x2="9.3" y2="15.1" fill="none" stroke="#fff"
                                      stroke-miterlimit="10" stroke-width="21"/>
                            </g>
                        </g>
                        <g id="Group_20309" data-name="Group 20309">
                            <g id="Group_20308" data-name="Group 20308" transform="translate(8.6 25.2)"
                               clip-path="url(#clip-path-4)">
                                <line id="Line_4020" data-name="Line 4020" x2="9.2" y2="15.1" fill="none" stroke="#fff"
                                      stroke-miterlimit="10" stroke-width="21"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>


            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Switch>
                        <Route exact path="/"><Home/></Route>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/about-infratech"><About smoother={smoother}/></Route>
                        <Route exact path="/business-domains" component={Business}/>
                        {/*<Route exact path="/media-center" component={Media}/>*/}
                        {/*<Route exact path={`/media-center/:slug`} component={MediaDetail}/>*/}
                        {/*<Route exact path={`/projects`} component={Project}/>*/}
                        {/*<Route exact path={`/project/:slug`} component={ProjectDetail}/>*/}
                        <Route exact path={`/projects/talenthunt`} component={TalentDetail}/>
                        <Route exact path={`/contact`} component={Contact}/>
                        <Route exact path={`/career`} component={Career}/>
                        <Route exact path={`/social-responsibility-and-esg`} component={Responsibility}/>
                        <Route exact path={`/animation-test`} component={Test}/>
                        <Route component={Error}/>
                    </Switch>
                    <Footer/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default App;
