import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover} from "../styles/globalStyleVars";
import {useSelector} from "react-redux";
import {BsChevronRight} from "react-icons/bs";

const MyComponent = () => {

    const d = new Date();
    let year = d.getFullYear();


    let getPost = useSelector(state => state.business)
    let domain = getPost?.posts?.domain_list;

    return (
        <StyledComponent className={'main-footer'}>
            <Container>
                <Row>
                    <Col sm={4} className={'main-footer__logo'}>
                        <img src="/images/static/logo_colored.svg" alt=""/>
                        <div className="main-footer__logo__group">
                            <h4>Visit</h4>
                            <a href="https://maps.app.goo.gl/JDoxvtfGvEqNPqmAA" target="_blank">NDE Infratech Ltd.<br/>House
                                - 83 Suhrawardy Avenue,<br/>Baridhara Diplomatic Zone,<br/>Dhaka -1212, Bangladesh</a>
                        </div>

                        <div className="main-footer__logo__group">
                            <h4>Email</h4>
                            <a href="mailto:info@ndeinfratech.com">info@ndeinfratech.com </a>
                        </div>

                        <ul>
                            <li><a className={'hover'} href="https://www.facebook.com/NDEInfratech/" target={"_blank"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7.847" height="14.65"
                                     viewBox="0 0 7.847 14.65">
                                    <path id="Path_2115" data-name="Path 2115"
                                          d="M1206.12,104.34l.406-2.652h-2.544v-1.72a1.325,1.325,0,0,1,1.495-1.432h1.157V96.278a14.1,14.1,0,0,0-2.053-.179,3.237,3.237,0,0,0-3.465,3.569v2.021h-2.329v2.652h2.329v6.409h2.866V104.34Z"
                                          transform="translate(-1198.787 -96.099)" fill="#fff"/>
                                </svg>

                            </a></li>

                            <li><a className={'hover'} href="https://www.linkedin.com/company/nde-infratech/"
                                   target={"_blank"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.063" height="10.561"
                                     viewBox="0 0 9.063 8.561">
                                    <path id="LinkedIn"
                                          d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                          transform="translate(-1087.435 -3916.306)" fill="#fff"></path>
                                </svg>
                            </a></li>

                            <li><a href="https://www.youtube.com/@ndesolutions6963" target={"_blank"}
                                   className={'hover'} target={'_blank'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.822" height="8.975"
                                     viewBox="0 0 12.822 8.975">
                                    <path id="Path_2114" data-name="Path 2114"
                                          d="M1146.9,113.13a2.813,2.813,0,0,0-2.813-2.813h-7.195a2.813,2.813,0,0,0-2.813,2.813v3.348a2.813,2.813,0,0,0,2.813,2.813h7.195a2.813,2.813,0,0,0,2.813-2.813Zm-4.231,1.925-3.226,1.6c-.126.068-.556-.023-.556-.167v-3.276c0-.146.433-.237.56-.165l3.089,1.68C1142.661,114.8,1142.8,114.985,1142.666,115.056Z"
                                          transform="translate(-1134.075 -110.317)" fill="#fff"/>
                                </svg>
                            </a></li>
                        </ul>
                    </Col>

                    <Col sm={{span: 7, offset: 1}} className={'main-footer__menus d-flex flex-wrap p-0'}>
                        <Col sm={{span: 3}} className={'main-footer__menus__single'}>
                            <ul>
                                <li style={{marginBottom: '40px'}}><Link to={'#'}>Home</Link></li>
                                <h4>About Us</h4>
                                <li><Link to={'/about-infratech'}>NDE Infratech</Link></li>
                                {/*<li><Link to={'/about-infratech#management-team'}>Management Team</Link></li>*/}
                                <li><Link to={'/about-infratech#partners'}>Partners</Link></li>
                                <li><Link to={'/about-infratech#customers'}>Customers</Link></li>
                                <li><Link to={'/about-infratech#licenses-memberships'}>Licenses & Memberships</Link>
                                </li>
                                <li><Link to={'/about-infratech#accolades'}>Accolades</Link></li>
                                <li><Link to={'/social-responsibility-and-esg'}>Social Responsibility & ESG</Link></li>
                            </ul>
                        </Col>

                        <Col sm={{span: 4, offset: 1}} className={'main-footer__menus__single'}>
                            <h4>Business Domains</h4>
                            <ul>
                                {/*<li><Link to={'/business-domains#power-energy'}>Power & Energy</Link></li>*/}
                                {/*<li><Link to={'/business-domains#transportation'}>Transportation <br/> Technology</Link>*/}
                                {/*</li>*/}
                                {/*<li><Link to={'/business-domains#citizens-services'}>Citizens’ Services </Link></li>*/}
                                {/*<li><Link to={'/business-domains#science-attraction'}>Science & Attraction </Link></li>*/}
                                {/*<li><Link to={'/business-domains#health-technology'}>Health Technology </Link></li>*/}
                                {/*<li><Link to={'/business--domains#security-surveillance'}>Security &*/}
                                {/*    Surveillance </Link></li>*/}
                                {
                                    domain && domain.length > 0 &&
                                    domain.map((element, index) => {
                                        return (
                                            <li key={index}>
                                                <Link
                                                    to={`/business-domains#${element?.post_name}`}>{element?.post_title}
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </Col>

                        <Col sm={{span: 3, offset: 1}} className={'main-footer__menus__single'}>

                            <ul>
                                {/*<li><Link to={'/projects'}>Projects</Link></li>*/}
                                {/*<li><Link to={'/media-center'}>Media Center</Link></li>*/}
                                <li><Link to={'/career'}>Career</Link></li>
                                <li><Link to={'/contact'}>Contact</Link></li>
                            </ul>
                        </Col>
                    </Col>
                </Row>

                <Row className={'copyright'}>
                    <Col sm={3}>
                        <p>© {year} NDE Infratech. All Rights Reserved. </p>
                    </Col>
                    <Col sm={{span: 8, offset: 1}}>
                        <a target={'_blank'} href="https://dcastalia.com/">Designed & Developed by Dcastalia</a>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 70px;

  .main-footer__logo {
    img {
      height: 40px;
    }

    &__group {
      margin-top: 40px;

      h4 {
        font-size: 16px;
        line-height: 24px;
        color: rgba(136, 136, 136, 0.5);
        margin-bottom: 10px;
      }

      a {
        font-size: 16px;
        line-height: 24px;
        color: #888888;
      }
    }

    ul {
      margin-top: 40px;
      display: flex;

      li {
        a {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: ${hover};
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:not(:nth-last-of-type(1)) {
          margin-right: 20px;
        }
      }
    }

  }

  .main-footer__menus {
    h4 {
      font-size: 16px;
      line-height: 24px;
      color: rgba(136, 136, 136, 0.5);
      margin-bottom: 20px;
    }

    a {
      font-size: 16px;
      line-height: 24px;
      color: #888888;
      margin-bottom: 15px;
      display: block;
    }
  }

  .copyright {
    margin-top: 80px;
    padding-bottom: 40px;

    p, a {
      font-size: 12px;
      line-height: 24px;
      color: rgba(136, 136, 136, 0.5);
    }
  }

  @media (max-width: 767px) {
    .main-footer__logo {
      min-width: 100%;
    }

    .main-footer__menus {
      min-width: 100%;
      margin: 0;
      margin-top: 80px;
    }
  }

  @media (max-width: 575px) {
    .main-footer__menus {
      margin-top: 50px;

      &__single {
        margin-top: 20px;
      }
    }

    .copyright {
      margin-top: 60px;
    }
  }

`;

export default MyComponent;
